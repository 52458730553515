import React from "react";
import theme from "theme";
import { Theme, Image, Span, Text, Button, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"6"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Section padding="0 0 0 0" background="#ffffff">
			<Override slot="SectionContent" width="100%" />
			<Box min-width="100px" min-height="100px" border-radius="8px 8px 0 0">
				<Box
					min-width="100px"
					min-height="100px"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/bg_jars.png?v=2023-09-20T13:17:32.348Z) 50% 70% /cover repeat scroll padding-box"
					padding="50px 0px 50px 0px"
					flex-direction="column"
					position="relative"
					border-radius="8px"
					margin="25px 0px 0px 0px"
					display="flex"
				>
					<Box
						min-width="100px"
						min-height="100px"
						flex-direction="column"
						padding="0px 50px 0px 50px"
						display="flex"
					>
						<Image src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/Logo_img.svg?v=2023-09-20T13:07:29.981Z" display="block" height="42px" />
						<Text
							margin="55px 0px 0px 0px"
							font="normal 400 40px/40px 'Druk', Impact, Haettenschweiler, 'Franklin Gothic Bold', Charcoal, 'Helvetica Inserat', 'Bitstream Vera Sans Bold', 'Arial Black', sans-serif"
							text-transform="uppercase"
							color="#ffffff"
							text-align="center"
							sm-font="--headline2Sm"
							sm-margin="30px 0px 280px 0px"
							display="flex"
							flex-direction="column"
							letter-spacing="1px"
						>
							<Span
								color="#FF6D03"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								border-color="--color-orange"
							>
								natural,{" "}
								<Span
									color="rgb(255, 255, 255)"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									clean,{" "}
								</Span>
							</Span>
							and backed by science
						</Text>
						<Text
							margin="0 0px 30px 0px"
							font="normal 400 22px/28.6px --fontFamily-sans"
							color="#F3F4F4"
							sm-font="normal 400 22px/30px --fontFamily-sans"
							text-align="center"
							display="flex"
							flex-direction="column"
						>
							The scientifically-developed supplements for men
						</Text>
						<Button
							background="--color-orange"
							text-transform="uppercase"
							font="normal 700 14px/20px --fontFamily-sans"
							padding="18px 28px 18px 28px"
							border-radius="4px"
							type="link"
							href="/q1/pers-care"
							text-decoration-line="initial"
							text-align="center"
							disabled={false}
							id="button-buy-1"
							className="buy-button"
						>
							buy now:{" "}
							<Span text-decoration-line="line-through" color="--lightD2">
								$39
							</Span>
							{" "}$27
						</Button>
					</Box>
					<Image
						src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/100natural_img.svg?v=2023-09-20T13:03:42.537Z"
						display="block"
						width="125px"
						position="absolute"
						right={0}
						top="50%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 50px 0" background="#ffffff">
			<Text
				margin="0px 0px 25px 0px"
				text-align="center"
				text-transform="uppercase"
				font="normal 400 40px/40px 'Druk', Impact, Haettenschweiler, 'Franklin Gothic Bold', Charcoal, 'Helvetica Inserat', 'Bitstream Vera Sans Bold', 'Arial Black', sans-serif"
				color="#1A6945"
				letter-spacing="1px"
			>
				<Span
					color="#1D4034"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="40px/40px Druk, Impact, Haettenschweiler, &quot;Franklin Gothic Bold&quot;, Charcoal, &quot;Helvetica Inserat&quot;, &quot;Bitstream Vera Sans Bold&quot;, &quot;Arial Black&quot;, sans-serif"
				>
					Choose your
				</Span>
				<br />
				supplements
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				background="#F3F4F4"
				border-radius="4px"
				display="flex"
				flex-direction="column"
				margin="0px 0px 20px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img_optimized.png?v=2023-09-26T15:51:26.272Z"
					display="block"
					border-radius="8px"
					srcSet="https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img_optimized.png?v=2023-09-26T15%3A51%3A26.272Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img_optimized.png?v=2023-09-26T15%3A51%3A26.272Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img_optimized.png?v=2023-09-26T15%3A51%3A26.272Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img_optimized.png?v=2023-09-26T15%3A51%3A26.272Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img_optimized.png?v=2023-09-26T15%3A51%3A26.272Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img_optimized.png?v=2023-09-26T15%3A51%3A26.272Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img_optimized.png?v=2023-09-26T15%3A51%3A26.272Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					padding="24px 24px 24px 24px"
					display="flex"
					flex-direction="column"
				>
					<Text
						margin="0px 0px 18px 0px"
						text-transform="uppercase"
						font="normal 700 20px/1.2 --fontFamily-sans"
						letter-spacing="1px"
						color="--darkGreen"
					>
						Premature ejaculation supplements
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 400 17px/22px --fontFamily-sans" color="--darkGreen">
						Formulated for men to last longer
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 0px"
						border-width="1px 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
					>
						<Text margin="0px 0px 0px 0px" text-transform="uppercase" font="normal 700 14px/20px --fontFamily-sans" color="--darkGreen">
							all natural ingredients
						</Text>
					</Box>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 18px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px --fontFamily-sans" color="--darkGreen">
							L-tryptophan{" "}
						</Text>
					</Box>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 18px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
							5-HTP{"\n "}
						</Text>
					</Box>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 18px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
							Citrulline{"\n\n "}
						</Text>
					</Box>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 18px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
							St. John’s Wort{"\n\n\n "}
						</Text>
					</Box>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 18px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
							Vitamin B12{"\n\n\n\n "}
						</Text>
					</Box>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 18px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
						margin="0px 0px 25px 0px"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
							Vitamin B3{"\n\n\n\n "}
						</Text>
					</Box>
					<Button
						background="--color-orange"
						text-transform="uppercase"
						font="normal 700 14px/29px --fontFamily-sans"
						padding="18px 28px 18px 28px"
						border-radius="4px"
						type="link"
						href="/q1/pers-care"
						text-decoration-line="initial"
						text-align="center"
						id="button-buy-2"
						className="buy-button"
					>
						buy now:{" "}
						<Span text-decoration-line="line-through" color="--lightD2">
							$39
						</Span>
						{" "}$27
					</Button>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				background="#F3F4F4"
				border-radius="4px"
				display="flex"
				flex-direction="column"
				margin="0px 0px 20px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img-1_optimized.png?v=2023-09-26T15:51:39.908Z"
					display="block"
					border-radius="8px"
					srcSet="https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img-1_optimized.png?v=2023-09-26T15%3A51%3A39.908Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img-1_optimized.png?v=2023-09-26T15%3A51%3A39.908Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img-1_optimized.png?v=2023-09-26T15%3A51%3A39.908Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img-1_optimized.png?v=2023-09-26T15%3A51%3A39.908Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img-1_optimized.png?v=2023-09-26T15%3A51%3A39.908Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img-1_optimized.png?v=2023-09-26T15%3A51%3A39.908Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/63d2a28df63de4002673c17c/images/cover-img-1_optimized.png?v=2023-09-26T15%3A51%3A39.908Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					padding="24px 24px 24px 24px"
					display="flex"
					flex-direction="column"
					margin="0px 0px 24px 0px"
				>
					<Text
						margin="0px 0px 18px 0px"
						text-transform="uppercase"
						font="normal 700 29px/1.2 --fontFamily-sans"
						letter-spacing="1px"
						color="--darkGreen"
					>
						Erectile dysfunction supplements
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
						Formulated for men to get stronger erections
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 0px"
						border-width="1px 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
					>
						<Text margin="0px 0px 0px 0px" text-transform="uppercase" font="normal 700 14px/20px --fontFamily-sans" color="--darkGreen">
							all natural ingredients
						</Text>
					</Box>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 18px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
							Citrulline{"\n "}
						</Text>
					</Box>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 18px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
							Pycnogenol{"\n\n\n\n\n\n\n "}
						</Text>
					</Box>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 18px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
							B3{"\n\n\n\n "}
						</Text>
					</Box>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 18px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
							B9{"\n\n\n\n\n "}
						</Text>
					</Box>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 18px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
							Vitamin C
							<br />
							{" "}
						</Text>
					</Box>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 18px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
							Vitamin E
							<br />
							{" "}
						</Text>
					</Box>
					<Box
						min-width="10px"
						min-height="10px"
						padding="8px 0px 8px 18px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="#D2D4D3"
						margin="0px 0px 25px 0px"
					>
						<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
							Ginseng{"\n\n\n\n "}
						</Text>
					</Box>
					<Button
						background="--color-orange"
						text-transform="uppercase"
						font="normal 700 14px/20px --fontFamily-sans"
						padding="18px 28px 18px 28px"
						border-radius="4px"
						type="link"
						href="/q1/pers-care"
						text-decoration-line="initial"
						text-align="center"
						id="button-buy-3"
						className="buy-button"
					>
						buy now:{" "}
						<Span text-decoration-line="line-through" color="--lightD2">
							$39
						</Span>
						{" "}$27
					</Button>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				grid-gap="16px 24px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					background="#F3F4F4"
					border-radius="8px"
					display="flex"
					align-items="center"
					flex-direction="column"
					padding="24px 24px 24px 24px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						width="70px"
						height="70px"
						display="flex"
						flex-direction="column"
						align-items="center"
						justify-content="center"
						margin="0px 0px 15px 0px"
					>
						<Image src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/icon.svg?v=2023-09-26T10:56:51.594Z" display="block" />
					</Box>
					<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px --fontFamily-sans" text-align="center" color="--darkGreen">
						100% Drug-Free
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#F3F4F4"
					border-radius="8px"
					display="flex"
					align-items="center"
					flex-direction="column"
					padding="24px 24px 24px 24px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						width="70px"
						height="70px"
						display="flex"
						flex-direction="column"
						align-items="center"
						justify-content="center"
						margin="0px 0px 15px 0px"
					>
						<Image src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/Frame%2018347.svg?v=2023-09-26T10:57:01.703Z" display="block" />
					</Box>
					<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px --fontFamily-sans" text-align="center" color="--darkGreen">
						All-Natural Ingredients
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#F3F4F4"
					border-radius="8px"
					display="flex"
					align-items="center"
					flex-direction="column"
					padding="24px 24px 24px 24px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						width="70px"
						height="70px"
						display="flex"
						flex-direction="column"
						align-items="center"
						justify-content="center"
						margin="0px 0px 15px 0px"
					>
						<Image src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/Frame%2018349.svg?v=2023-09-26T10:57:01.683Z" display="block" />
					</Box>
					<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" text-align="center" color="--darkGreen">
						Research-Backed
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#F3F4F4"
					border-radius="8px"
					display="flex"
					align-items="center"
					flex-direction="column"
					padding="24px 24px 24px 24px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						width="70px"
						height="70px"
						display="flex"
						flex-direction="column"
						align-items="center"
						justify-content="center"
						margin="0px 0px 15px 0px"
					>
						<Image src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/Frame%2018348.svg?v=2023-09-26T10:57:01.724Z" display="block" />
					</Box>
					<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px --fontFamily-sans" text-align="center" color="--darkGreen">
						Physician-Formulated
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			min-width="100px"
			min-height="100px"
			quarkly-title="WHAT THE CREW HAS TO SAY"
			padding="0 0 0 0"
			id="rec351831498"
			inner-max-width="1158px"
			background="#ffffff"
		>
			<Override slot="SectionContent" />
			<Text
				margin="0px 0px 25px 0px"
				text-align="center"
				text-transform="uppercase"
				font="normal 800 40px/40px 'Druk', Impact, Haettenschweiler, 'Franklin Gothic Bold', Charcoal, 'Helvetica Inserat', 'Bitstream Vera Sans Bold', 'Arial Black', sans-serif"
				color="#1A6945"
				letter-spacing="1px"
			>
				<Span
					color="#1D4034"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Real members
				</Span>
				<br />
				Real Reviews
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				flex-wrap="wrap"
				grid-template-columns="repeat(8, 1fr)"
				grid-gap="30px 20px"
				grid-template-rows="repeat(1, 1fr)"
				overflow-x="scroll"
				className="horizontal-scroll-box"
				margin="0px 0px 25px 0px"
			>
				<Box padding="24px 24px 24px 24px" background="#F3F4F4" border-radius="8px" width="70vw">
					<Image
						src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/Frame%2033.svg?v=2023-07-18T22:26:54.452Z"
						display="block"
						margin="0px 0px 20px 0px"
						width="auto"
						height="12px"
						object-position="0% 50%"
						alt="5 stars"
					/>
					<Text margin="0px 0px 10px 0px" font="400 17px/22px --fontFamily-sans" color="--darkGreen">
						"I am lucky to be one of beta-testers. In after 4 week happy to report incredible results"{" "}
					</Text>
					<Text margin="0px 0px 18px 0px" font="700 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen" text-align="right">
						Jeffrey
					</Text>
				</Box>
				<Box
					padding="24px 24px 24px 24px"
					background="#F3F4F4"
					border-radius="8px"
					width="70vw"
					sm-width="60vw"
				>
					<Image
						src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/Frame%2033.svg?v=2023-07-18T22:26:54.452Z"
						display="block"
						margin="0px 0px 20px 0px"
						width="auto"
						height="12px"
						object-position="0% 50%"
						alt="5 stars"
					/>
					<Text margin="0px 0px 10px 0px" font="400 17px/22px --fontFamily-sans" color="--darkGreen">
						"I've been using these supplements for a while, and they've really helped with my erectile dysfunction. While it's not a magic cure, I've noticed improved performance, and it's been a positive change for me."{" "}
					</Text>
					<Text margin="0px 0px 18px 0px" font="700 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen" text-align="right">
						Rodrigo
					</Text>
				</Box>
				<Box
					padding="24px 24px 24px 24px"
					background="#F3F4F4"
					border-radius="8px"
					width="70vw"
					sm-width="60vw"
				>
					<Image
						src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/Frame%2033.svg?v=2023-07-18T22:26:54.452Z"
						display="block"
						margin="0px 0px 20px 0px"
						width="auto"
						height="12px"
						object-position="0% 50%"
						alt="5 stars"
					/>
					<Text margin="0px 0px 10px 0px" font="400 17px/22px --fontFamily-sans" color="--darkGreen">
						"I was skeptical, but these supplements have genuinely made a difference in managing my condition. My confidence is up, and I've seen improvement in my performance."
					</Text>
					<Text margin="0px 0px 18px 0px" font="700 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen" text-align="right">
						Mark
					</Text>
				</Box>
				<Box
					padding="24px 24px 24px 24px"
					background="#F3F4F4"
					border-radius="8px"
					width="70vw"
					sm-width="60vw"
				>
					<Image
						src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/Frame%2033.svg?v=2023-07-18T22:26:54.452Z"
						display="block"
						margin="0px 0px 20px 0px"
						width="auto"
						height="12px"
						object-position="0% 50%"
						alt="5 stars"
					/>
					<Text margin="0px 0px 10px 0px" font="400 17px/22px --fontFamily-sans" color="--darkGreen">
						"I wanted a natural solution for my erectile issues, and these supplements have provided some relief."
					</Text>
					<Text margin="0px 0px 18px 0px" font="700 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen" text-align="right">
						Rohit
					</Text>
				</Box>
			</Box>
			<Button
				background="--color-orange"
				text-transform="uppercase"
				font="normal 700 14px/20px --fontFamily-sans"
				padding="18px 28px 18px 28px"
				border-radius="4px"
				type="link"
				href="/q1/pers-care"
				text-decoration-line="initial"
				text-align="center"
				id="button-buy-4"
				className="buy-button"
			>
				buy now:{" "}
				<Span text-decoration-line="line-through" color="--lightD2">
					$39
				</Span>
				{" "}$27
			</Button>
		</Section>
		<Section
			min-width="100px"
			min-height="100px"
			quarkly-title="WHAT THE CREW HAS TO SAY"
			padding="50px 0 80px 0"
			id="rec351831498"
			inner-max-width="1158px"
			background="#ffffff"
		>
			<Override slot="SectionContent" />
			<Text
				margin="0px 0px 25px 0px"
				text-align="center"
				text-transform="uppercase"
				font="normal 800 40px/40px 'Druk', Impact, Haettenschweiler, 'Franklin Gothic Bold', Charcoal, 'Helvetica Inserat', 'Bitstream Vera Sans Bold', 'Arial Black', sans-serif"
				color="#1A6945"
				letter-spacing="1px"
			>
				Improving Your Sexual{" "}
				<Span color="#1D4034">
					Health Starts Right Here
				</Span>
			</Text>
			<Box
				min-width="10px"
				min-height="10px"
				background="#F3F4F4"
				display="flex"
				justify-content="space-between"
				align-items="center"
				padding="14px 24px 14px 24px"
				border-radius="8px"
				margin="0px 0px 15px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px --fontFamily-sans" color="--darkGreen">
					Personalized plan{" "}
				</Text>
				<Image src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/svg.svg?v=2023-09-26T10:57:49.767Z" display="block" />
			</Box>
			<Box
				min-width="10px"
				min-height="10px"
				background="#F3F4F4"
				display="flex"
				justify-content="space-between"
				align-items="center"
				padding="14px 24px 14px 24px"
				border-radius="8px"
				margin="0px 0px 15px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
					International delievery
				</Text>
				<Image src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/svg-1.svg?v=2023-09-26T10:57:49.764Z" display="block" />
			</Box>
			<Box
				min-width="10px"
				min-height="10px"
				background="#F3F4F4"
				display="flex"
				justify-content="space-between"
				align-items="center"
				padding="14px 24px 14px 24px"
				border-radius="8px"
			>
				<Text margin="0px 0px 0px 0px" font="normal 400 17px/22px -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" color="--darkGreen">
					Adherence support
				</Text>
				<Image src="https://uploads.quarkly.io/63d2a28df63de4002673c17c/images/svg-2.svg?v=2023-09-26T10:57:49.764Z" display="block" />
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65093a108bd7e600185e78d9"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfHead"} rawKey={"6511ac8515cc038ca22e9a60"}>
				{"window.mainTracker = \"gtag\";\nwindow.gtagTrackerID = \"G-2WL1E4LC2C\";\nfunction gtag() {\n  dataLayer.push(arguments);\n}\n\nfunction loadGTM() {\n  (function (w, d, s, i) {\n    var f = d.getElementsByTagName(s)[0],\n      j = d.createElement(s);\n    j.async = true;\n    j.src = \"https://www.googletagmanager.com/gtag/js?id=\" + i;\n    j.onload = function() {\n      gtag(\"js\", new Date());\n      gtag(\"config\", i, {});\n    }\n    f.parentNode.insertBefore(j, f);\n  })(window, document, \"script\", window.gtagTrackerID);\n}\n\nwindow.onload = function() {\n  if (window.requestIdleCallback) {\n    window.requestIdleCallback(loadGTM, { timeout: 150000 });\n  } else {\n    setTimeout(loadGTM, 2000);\n  }\n}\n"}
			</script>
			<script place={"endOfHead"} rawKey={"651479974417680f7b1a3949"}>
				{"document.addEventListener(\"DOMContentLoaded\", function() {\n    let buttons = document.querySelectorAll(\".buy-button\");\n\n    buttons.forEach(button => {\n        button.addEventListener(\"click\", function() {\n            gtag('event', 'click', {\n                'event_category': 'button',\n                'event_label': 'supp-button-' + button.id \n            });\n            console.log('supp-button-' + button.id);\n        });\n    });\n});"}
			</script>
		</RawHtml>
	</Theme>;
});